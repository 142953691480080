/* src/components/Login.css */

.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .login-container h2 {
    text-align: center;
  }
  
  .login-container form {
    display: flex;
    flex-direction: column;
  }
  
  .login-container input {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .login-container button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .error {
    color: red;
    text-align: center;
  }
  
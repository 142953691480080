.glass-container {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .glass-card {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .glass-popup {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  /* Resetting the styles for card images to default */
.glass-card img {
    width: auto; /* Keeps the original width of the image */
    height: auto; /* Keeps the original height of the image */
    max-width: 100%; /* Prevents the image from exceeding its container */
    max-height: 100%; /* Ensures the image fits within its container */
    padding: 0; /* Removes any padding */
    margin: 0; /* Removes any margin */
}

.text-center {
    position: relative;
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
    border: 2px solid rgba(230, 92, 0, 0.3); /* Semi-transparent border color */
    border-radius: 8px; /* Rounded corners */
    backdrop-filter: blur(10px); /* Glass effect blur */
    padding: 8px; /* Space inside the container */
    transition: transform 0.3s; /* Transition for hover effect */
}

.text-center img {
    display: block; /* Ensures the image takes full width of its container */
    border-radius: 8px; /* Matches the rounded corners of the container */
}

.text-center:hover {
    transform: scale(1.05); /* Scale up the container on hover */
}

/*turn off to see result or remove it 8*/
.glass-container { /*turn off to see result or remove it 8*/
    background: rgba(255, 255, 255, 0.0); /* Fully transparent background */
    padding: 1.5rem; /* Optional: adjust padding */
    backdrop-filter: blur(10px); /* Optional: apply glass effect blur */
}

/* Ensure no border or background is applied */
.text-xl {
    border: none; /* No border */
    background: none; /* No background */
}

/* If you need the text color to be white or any other color */
.text-white {
    color: white; /* Ensures the text is visible */
}

/* General Styling */
.deck-builder-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: white;
    background-color: transparent;
  }
  
  
  h1, h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Flexbox layout for select options, search, and buttons */
  .select-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px;
    width: 100%;
  }
  
  .select-container select,
  .select-container input,
  .select-container button {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .select-container input {
    flex-grow: 2;
  }
  
  .select-container button {
    background-color: #ff6600;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .select-container button:hover {
    background-color: #e65c00;
  }
  button {
    background-color: #ff660000;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
    padding: 10px;
    font-size: 1rem;
    position: relative; /* Make the button's position relative */
    top: -3px; /* Move the button up by 5px */
  }
  
  /*button {
    background-color: #ff6600;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
    padding: 10px;
    font-size: 1rem;
    
  } */
  
  button:hover {
    background-color: #e65c00;
  }
  
  .copy-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .copy-button button {
    background-color: #ff6600;
    font-size: 1.1rem;
    padding: 12px 20px;
    border-radius: 8px;
  }
  .toggle-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .toggle-button button {
    background-color: #ff6600;
    font-size: 1.1rem;
    padding: 12px 20px;
    border-radius: 8px;
  }
  
  .copy-button button:hover {
    background-color: #e65c00;
  }
  
  .deck-section {
    margin: 30px 0;
  }
  
  .deck-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }
  
  .card {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
    border-radius: 8px;
    text-align: center;
    transition: transform 0.2s ease;
  }
  
  
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .level {
    font-size: 0.9rem;
    color: #ffcc00;
  }
  
  .empty-deck-message {
    grid-column: span 4;
    text-align: center;
  }
  
  #deck-stats {
    margin-top: 20px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
  
  .cards-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .card img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    object-fit: contain;
    
  }
  
  button {
    margin-top: 10px;
    align-self: center;
  }
  
  .search-section {
    margin: 20px 0;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    padding: 10px;
    background-color: #3c2a67;
    color: white;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.2s ease;
  }
  
  li:hover {
    background-color: #2b2251;
  }
  
  .selected-card {
    margin-top: 10px;
    font-weight: bold;
    color: #fff;
  }
  
  /* Mobile and Tablet Responsive Styling */
  @media only screen and (max-width: 900px) {
    .select-container {
      flex-direction: column;
      gap: 10px;
    }
  
    .deck-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  
    .cards-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  
    button {
      font-size: 0.8rem;
      padding: 5px;
    }
  
    h1, h2 {
      font-size: 1.2rem;
    }
  
    .card img {
      width: 50px;
      height: 50px;
    }
  
    .deck-section, .search-section {
      margin: 10px 0;
    }
  
    .deck-grid {
      gap: 10px;
    }
  
    #deck-stats {
      font-size: 0.9rem;
    }
  
    ul {
      font-size: 0.9rem;
    }
  
    li {
      padding: 8px;
    }
  
    .empty-deck-message {
      font-size: 0.9rem;
    }
  }
  
  /* Small Mobile Styling */
  @media only screen and (max-width: 400px) {
    .card img {
      max-width: 70px;
    }
  
    button {
      font-size: 0.7rem;
    }
  
    h1, h2 {
      font-size: 1rem;
    }
  }
  
  /*for scroll inside container*/
  #cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    max-height: 400px; /* Set a fixed height for the container */
    overflow-y: auto;  /* Enable vertical scrolling */
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2); /* Optional: Add background color */
    border-radius: 8px; /* Optional: Add rounded corners */
  }
  
  /* Optional: Customize scrollbar appearance */
  #cards-container::-webkit-scrollbar {
    width: 8px;
  }
  
  #cards-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4); /* Scrollbar thumb color */
    border-radius: 4px;
  }
  
  #cards-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.6); /* Hover effect for scrollbar */
  }
  
  /* Style for the Available Cards search input */
  #available-search-input {
    
    padding: 10px;
   
    width: calc(100% - 10px);
    color: #fff;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease-in-out;
    margin-bottom: 10px;
    margin-left: 10px;
    background-color: #ffffff20;
    box-sizing: border-box; /* Ensures padding is included in the width */
    margin-right: 10px;
  }
  
  #available-search-input:focus {
    border-color: #fff;
    outline: none;
  }
  
  .captcha-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #242121;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    border-radius: 8px;
    text-align: center;
  }
  
  .captcha-overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .captcha-image-container {
    position: relative;
    margin: 20px 0;
  }
  
  .captcha-image-container img {
    width: 100%;
    height: auto;
  }
  
  .captcha-slider {
    width: 100%;
    margin-top: 10px;
  }
  
  .captcha-error {
    color: red;
    margin-top: 10px;
  }
  
  /* Modal styling */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: rgba(255, 255, 255, 0.15);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 500px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    color: #fff;
    text-align: center;
  }
  
  .glass-background {
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 30px;
    position: relative;
  }
  
  .close-button {
    background-color: #ff6600;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .close-button:hover {
    background-color: #e65c00;
  }
  
  .help-button {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .help-button button {
    background-color: transparent;
    color: #e65c00;
    padding: 10px 20px;
    font-size: 1.1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    left: 670px;
    
  }
  
  .help-button button:hover {
    background-color: #e65c00;
    color:#fff;
  }
  
  
  .category-banner {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    color: #ffffff;
    font-size: 28px;
    font-weight: bold;
    margin-top:20px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  
  
  
  
  .text-xl {
    border: none; /* No border */
    background: none; /* No background */
  }
  
  /* If you need the text color to be white or any other color */
  .text-white {
    color: white; /* Ensures the text is visible */
  }
  
  
  
/* src/components/AdminDeckCreator.css */

.admin-deck-creator-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 30px;
  border: 2px solid #333;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.admin-deck-creator-container h1,
.admin-deck-creator-container h2,
.admin-deck-creator-container h3 {
  text-align: center;
  margin-bottom: 20px;
}

.admin-deck-creator-container input,
.admin-deck-creator-container textarea,
.admin-deck-creator-container select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

.admin-deck-creator-container button {
  padding: 10px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.admin-deck-creator-container button:hover {
  background-color: #555;
}

.external-link {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.external-link input {
  flex: 1;
}

.admin-deck-creator-container ul {
  list-style: none;
  padding: 0;
}

.admin-deck-creator-container li {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.admin-deck-creator-container li h3 {
  margin-bottom: 10px;
}

.admin-deck-creator-container li button {
  margin-right: 10px;
}

.admin-deck-creator-container p {
  text-align: center;
  font-weight: bold;
  color: green;
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #3c2a67;
  
}

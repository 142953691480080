/* General Blog Styling */
.blog-page-container {
  padding: 0;
  max-width: 100vw;
  margin: 0;
  overflow-x: hidden;
}

.blog-header {
  text-align: center;
  font-size: 2rem; /* Adjusted for mobile */
  margin-bottom: 20px; /* Reduced bottom margin */
  color: #fff;
}

/* Glass Effect Styling for Blog Posts */
.glass-effect {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto; /* Allow for flexible height */
}

/* Set square shape for each post */
.blog-post {
  width: 100%; /* Ensure it takes full width */
  aspect-ratio: 1; /* Maintain square aspect ratio */
  max-height: 200px; /* Limit height to prevent elongation */
}

/* Blog Content Styling */
.blog-image-full {
  width: 100%;
  height: 50%; /* Adjusted height for image */
  object-fit: cover; /* Ensure image covers the area */
  border-radius: 10px 10px 0 0;
}

.blog-title {
  font-size: 1rem; /* Smaller font size for title */
  margin: 5px 0;
  color: #fff;
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
  white-space: nowrap; /* Prevent text wrapping */
}

.blog-description {
  font-size: 0.75rem; /* Smaller font size for description */
  color: #ddd;
  margin: 5px 0; /* Reduced margins */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
  white-space: nowrap; /* Prevent text wrapping */
}

.read-more-btn {
  background-color: #7b36ea;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.75rem; /* Smaller button font size */
  cursor: pointer;
  transition: background-color 0.3s;
}

.read-more-btn:hover {
  background-color: #3206aa;
}

/* Grid for Blog Posts */
.blog-posts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 10px; /* Smaller gap between posts */
  padding: 0 10px; /* Optional: add horizontal padding for better spacing */
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-header {
      font-size: 1.5rem; /* Adjust header size for mobile */
  }

  .blog-posts-grid {
      grid-template-columns: repeat(3, 1fr); /* Keep three columns on small screens */
  }

  .glass-effect {
      padding: 10px; /* Slightly reduce padding on smaller screens */
  }
}

@media (max-width: 480px) {
  .blog-header {
      font-size: 1.2rem; /* Further adjust header size for smaller screens */
  }

  .blog-title {
      font-size: 0.9rem; /* Further reduce title size */
  }

  .blog-description {
      font-size: 0.65rem; /* Further reduce description size */
  }

  .read-more-btn {
      font-size: 0.65rem; /* Smaller button font size */
      padding: 5px 8px; /* Adjust padding for buttons */
  }
}

.read-more-btn {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 3px;
  font-size: 0.75rem;
  cursor: pointer;
  transition: background-color 0.3s;
  left: 10px;
  
}